import React, {useState} from "react";
import { bool, string } from "prop-types";
import Section from "../Base/Section";
import Heading from "../Base/HeadingBuilder";
import Image from "../../components/ImageQuerys/LargeFeaturesImages";
import YouTube from "react-youtube";
import play from "../../images/svg/play.svg";

const textWithVideoAndImage = ({
  className,
  heading,
  paragraph,
  imageName,
  thumbnailImageName,
  videoId,
  withImageOnly,
}) => {
  const [playing, setPlaying] = useState(false);

  const startPlaying = () => {
    setPlaying(true);
  };
  const endPlaying = () => {
    setPlaying(false);
  };

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };

  if (withImageOnly) {
    return (
      <Section className={className}>
      <div
        className={`
        grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 
      `}
      >
        <div className="min-w-[200px]">
          <Image
            imageName={imageName}
            className=""
          /> 
        </div>

        <div className="flex flex-col text-center mb-12 items-center justify-center mt-10 md:mt-0">
          {heading && (
            <Heading level={3} injectionType={3} withBlueSymbol className="">
              {heading}
            </Heading>
          )}
            <p className="text-p text-black-300 text-center mx-auto mt-2 max-w-[1040px]">
              {paragraph}
            </p>
        </div>
      </div>
    </Section>
    )
  }

  return (
    <Section className={className}>
      <div
        className={`
        flex flex-col mt-32 md:mt-16 
      `}
      >
        <div className="flex flex-col text-center mb-12">
          {heading && (
            <Heading level={3} injectionType={3} withBlueSymbol className="">
              {heading}
            </Heading>
          )}
            <p className="text-p text-black-300 text-center mx-auto mt-2 max-w-[1040px]">
              {paragraph}
            </p>
        </div>
       
         <div
          className={`
          grid grid-cols-1 lg:grid-cols-2 gap-10
          `}
        >
          {!playing ? (
            <div className="w-full relative">
              <Image imageName={thumbnailImageName}/>

              <button
                type="button"
                className={`
                absolute left-[50%] top-[50%] mx-auto bg-primary border-none rounded-full
                text-white text-p font-bold py-4 px-10
                hover:underline cursor-pointer
                flex items-center
                min-w-[215px]
              `}
                style={{ transform: "translate(-50%, -50%)" }}
                onClick={startPlaying}
              >
                Watch Video <img alt="play button" src={play} className="ml-4" />
              </button>
            </div>
          ) : (
            <div className="w-full relative">
              <YouTube
                videoId={videoId}
                onEnd={endPlaying}
                onReady={onPlayerReady}
                className="min-h-[400px] md:min-h-[600px] rounded-[16px]"
                opts={{
                  playerVars: {
                    autoplay: 1,
                  },
                  height: "auto",
                  width: "100%",
                }}
              />
            </div>
          )}
        <div className="min-w-[200px]">
        <Image
          imageName={imageName}
          className={`
          
        `}
        /> 
        </div>
        </div>
      </div>
    </Section>
  );
};

textWithVideoAndImage.propTypes = {
  className: string,
  heading: string,
  paragraph: string,
  thumbnailImageName: string.isRequired,
  videoId: string.isRequired,
  imageName: string.isRequired,
  withImageOnly: bool,
};

textWithVideoAndImage.defaultProps = {
  className: "",
  heading: null,
  paragraph: null,
  withImageOnly: false,
};

export default textWithVideoAndImage;
